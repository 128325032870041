//
//
//
//
//
//
//
//
//

import ReportPage from "@/components/ReportPage.vue";
import AppTabsLinks from "@/components/AppTabsLinks.vue";
export default {
  name: "Home",
  data() {
    return {
      tabs: [{
        title: "帐号设定",
        route: "/profile/settings"
      }, {
        title: "套餐与支付",
        route: "/profile/plan"
      }, {
        title: "查询历史记录",
        route: "/profile/history"
      }, {
        title: "合作伙伴计划",
        route: "/profile/partner"
      }]
    };
  },
  components: {
    ReportPage,
    AppTabsLinks
  }
};